import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getSales", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/getCustomerSalesOne/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchClients() {
      return new Promise((resolve, reject) => {
        axios
          .get("/apps/invoice/clients")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchHelperSales(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getHelperSales", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    addInvoice(ctx, invoiceData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/addInvoice", { invoiceData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    editInvoice(ctx, invoiceData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/editInvoice", { invoiceData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    statusPayClover(ctx, data) {
      console.log("Recibi algo =>", data);
      return new Promise((resolve, reject) => {
        axios
          .get("/getStatusCloverPay", { params: data })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchHelpers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getHelpes", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    addCountry(ctx, customerData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/addCustomer", { customerData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    addTips(ctx, tips) {
      return new Promise((resolve, reject) => {
        axios
          .post("/addTipsEmployee", tips)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
